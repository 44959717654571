.iconos {
  height: 2vw;
  
}

.botonIcono {
  margin: 0.2vw;
}

span {
  visibility: visible !important;
}