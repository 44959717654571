
.cardContainer {
  
  display: flex;
  flex-wrap: wrap;

  overflow:auto;

  flex-wrap: wrap;


}

.card {
  cursor:pointer;
  display: grid;
  grid-template-columns: 20vw;
  grid-template-rows: 15vw 20vh 5vh;
  grid-template-areas: "image" "text" "foot";
  border-radius: 18px;
  background: rgb(25, 108, 103);
  box-shadow: none;
  text-align: center;
  margin-left: 1vw;
  margin-top: 2vw;
}

.card:hover {
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.9);
  background: rgb(78, 214, 205);
}

.card:hover .cardTextBody {
  color: rgb(4, 4, 4);
}

.card:hover .cardFootBody {
  background: #714ee2;
}

.card:hover .cardImage {
  filter: brightness(120%);
}

.cardImage {
  grid-area: image;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-size: cover;
}

.cardBody {
  grid-area: text;
  margin: 25px;
}

.cardTextTitle {
  margin-top: 0px;
  font-size: 2vw;
  box-sizing: border-box;
  min-width: 0px;
  line-height: 1.2;
  margin: 0px;
  background: linear-gradient(
    110.78deg,
    rgb(118, 230, 80) -1.13%,
    rgb(249, 214, 73) 15.22%,
    rgb(240, 142, 53) 32.09%,
    rgb(236, 81, 87) 48.96%,
    rgb(255, 24, 189) 67.94%,
    rgb(26, 75, 255) 85.34%,
    rgb(98, 216, 249) 99.57%
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.cardTextBody {
  color: rgb(223, 220, 220);
  font-size: 1vw;
  font-weight: 300;
}

.cardTextBody:hover {
  color:black;
}

.cardFootBody {
  grid-area: foot;
  display: grid;
  /* grid-template-columns: 1fr 1fr 1fr; */

  grid-template-rows: 1fr;

  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background: #5930e5;
}

.cardFoot {
  font-size: 1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  padding: 10px;
}

.linkText {
  color: #fff;
  text-decoration: none;
}