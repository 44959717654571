@import url('https://fonts.googleapis.com/css2?family=Encode+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {background-color:#f7f7f7}



#login-background-centrado{
		min-height: 100vh;
    width: 90%;
		position: center;
		font-family: 'Encode Sans', sans-serif;
	}

  #login-background{
		min-height: 100vh;
		background-size: cover;
		position: relative;

		background: url(img/20.jpg) no-repeat center center #335966;		
		font-family: 'Encode Sans', sans-serif;
	}

.justify-content-center {
    justify-content: center !important;    
}

.justify-content-right {
  justify-content: right !important;    
}

.text-right {text-align:right !important}

.btn{cursor: pointer !important;}

a {text-decoration:none}

.logo {width:200px}

.btn-width{width:150px}

.form-control{background-color: rgba(255,255,255,.5);}

.btn-cian{color: #fff;
background-color: #00C0F3;
border-color: #00C0F3;}

.btn-cian:hover{color: #fff;
background-color: #00A8F2;
border-color: #00A8F2;}

.btn-outline-cian{color:#00C0F3;
  background-color: #ffff;
  border-color: #00C0F3;}
  
.btn-outline-cian:hover{color: #fff;
  background-color: #00C0F3;
  border-color: #00C0F3;}

.btn-link{color: rgba(255,255,255,1);
}
.btn-link:hover{color: rgba(255,255,255,.7);
}
	
.boton-home {position: relative;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);    
	margin-bottom: 80px;
	}
	
.boton-home:before {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  content: '';
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 0;
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 80%);
  /* W3C */
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform, opacity;
  transition-property: transform, opacity;
}
.boton-home:hover, .boton-home:focus, .boton-home:active {
  -webkit-transform: translateY(-15px);
  transform: translateY(-15px);
  -webkit-animation-name: hover;
  animation-name: hover;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}
.boton-home:hover:before, .boton-home:focus:before, .boton-home:active:before {
  opacity: .8;
  -webkit-transform: translateY(6px);
  transform: translateY(6px);
  -webkit-animation-name: hover-shadow;
  animation-name: hover-shadow;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}


.form-label {
  margin-bottom: 0.15rem !important;
}
.boldoption {
	font-weight: bold;
}
table th span, label span {visibility: hidden;}
table th span:before, table th span:after {visibility: visible !important;}

.App {
    font-family: sans-serif;
    text-align: center;
  }
  * {
    margin:0px;
    padding:0px;
  }
  
  #header {
    margin:auto;
    width:500px;
    font-family:Arial, Helvetica, sans-serif;
  }
  
  ul, ol {
    list-style:none;
  }
  
  .nav > li {
    float:left;
  }
  .imagenVane {
    box-shadow: 0 0 15px #999;
     border-radius: 50%;
     width: 20%;
   }
  .nav li a {
    background-color:#000;
    color:#fff;
    text-decoration:none;
    padding:10px 12px;
    display:block;
  }
  
  .nav li a:hover {
    background-color:#434343;
  }
  
  .nav li ul {
    display:none;
    position:absolute;
    min-width:140px;
  }
  
  .nav li:hover > ul {
    display:block;
  }
  
  .nav li ul li {
    position:relative;
  }
  
  .nav li ul li ul {
    right:-140px;
    top:0px;
  }